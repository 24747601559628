import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

/*
Detect Editor
*/
export const isEditor = (flowApi: PlatformControllerFlowAPI) =>
  !flowApi.environment.isViewer;

export const getPrefixApi = (flowApi: PlatformControllerFlowAPI) => {
  const api = isEditor(flowApi) ? '' : '/_api';
  return api;
};
