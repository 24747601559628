import { WidgetDesignManifestBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignManifestBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import { presets } from './presets';

export default function mainPresetsBuilder(
  widgetDesignBuilder: WriteOnlyBuilder<WidgetDesignManifestBuilder>,
  isEditorX: boolean,
) {
  const myPresets = isEditorX
    ? presets.filter((preset) => preset.isDisplayOnEditorX)
    : presets;

  widgetDesignBuilder.setPresets(myPresets as any);
}
