import defaulBoxNewImage from '../assets/images/presets/Default Box New.png';
import cropedDeviceImage from '../assets/images/presets/Cropped Device.png';
import qrSubtitleInBoxImage from '../assets/images/presets/QR+sub title in box.png';
import overlappingImage from '../assets/images/presets/Overlapping.png';
import defaulBoxSolidBGImage from '../assets/images/presets/Default Box + Solid BG.png';
import stripImage from '../assets/images/presets/Strip.png';
import titleQrBadges from '../assets/images/presets/Title + QR code + Badges.png';
import imageOnly2 from '../assets/images/presets/Img Only2.png';
import badgesVertical from '../assets/images/presets/Badges vertical.png';
import badgesHorizontal from '../assets/images/presets/Badges horizontal.png';
import titleQr from '../assets/images/presets/Title + QR Code.png';
import qr from '../assets/images/presets/QR code.png';
import qrBadges from '../assets/images/presets/QR + badges.png';
import centerTitleSubBadge from '../assets/images/presets/Center title+sub+badge+QR.png';

import appElements from './appElements';
import {
  MobilePresetIds,
  MobilePresetNames,
  PresetIds,
  PresetNames,
} from '../enums/presets';

export const mobilePresets = [
  { name: MobilePresetNames.defaultBox, id: MobilePresetIds.defaultBox },
  {
    name: MobilePresetNames.QRAndSubtitleInBox,
    id: MobilePresetIds.QRAndSubtitleInBox,
  },
  { name: MobilePresetNames.croppedDevice, id: MobilePresetIds.croppedDevice },
  { name: MobilePresetNames.overlapping, id: MobilePresetIds.overlapping },
  {
    name: MobilePresetNames.titleAndQRCode,
    id: MobilePresetIds.titleAndQRCode,
  },
  { name: MobilePresetNames.badgesOnly, id: MobilePresetIds.badgesOnly },
  {
    name: MobilePresetNames.centerTitleSubBadge,
    id: MobilePresetIds.centerTitleSubBadge,
  },
];

export const presets: {
  name: string;
  id: string;
  src: any;
  layout: { width: string; height: string };
  withoutElements?: string[];
  width: number;
  height: number;
  isDisplayOnEditorX: boolean;
  connectedMobilePresetID: string;
}[] = [
  {
    name: PresetNames.defaultBoxNew,
    id: PresetIds.defaultBoxNew,
    connectedMobilePresetID: MobilePresetIds.defaultBox,
    src: defaulBoxNewImage,
    layout: { width: '240px', height: '142px' },
    withoutElements: [
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 562,
  },
  {
    name: PresetNames.croppedDevice,
    id: PresetIds.croppedDevice,
    connectedMobilePresetID: MobilePresetIds.croppedDevice,
    src: cropedDeviceImage,
    layout: { width: '240px', height: '114px' },
    withoutElements: [
      appElements.QRCode.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 420,
  },
  {
    name: PresetNames.QRAndSubtitleInBox,
    id: PresetIds.QRAndSubtitleInBox,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: qrSubtitleInBoxImage,
    layout: { width: '240px', height: '115px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 436,
  },
  {
    name: PresetNames.overlapping,
    id: PresetIds.overlapping,
    connectedMobilePresetID: MobilePresetIds.overlapping,
    src: overlappingImage,
    layout: { width: '240px', height: '153px' },
    withoutElements: [
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 603,
  },
  {
    name: PresetNames.defaultBoxAndSolidBG,
    id: PresetIds.defaultBoxAndSolidBG,
    connectedMobilePresetID: MobilePresetIds.defaultBox,
    src: defaulBoxSolidBGImage,
    layout: { width: '240px', height: '120px' },
    withoutElements: [
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 980,
    height: 488,
  },
  {
    name: PresetNames.strip,
    id: PresetIds.strip,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: stripImage,
    layout: { width: '240px', height: '57px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 226,
  },
  {
    name: PresetNames.tiltleAndQRcodeAndBadges,
    id: PresetIds.tiltleAndQRcodeAndBadges,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: titleQrBadges,
    layout: { width: '240px', height: '77px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.smsInputs.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 576,
    height: 183,
  },
  {
    name: PresetNames.badgesHorizontal,
    id: PresetIds.badgesHorizontal,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: badgesHorizontal,
    layout: { width: '110px', height: '55px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 290,
    height: 43,
  },
  {
    name: PresetNames.QRAndBadges,
    id: PresetIds.QRAndBadges,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: qrBadges,
    layout: { width: '110px', height: '52px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 255,
    height: 94,
  },
  {
    name: PresetNames.QRcode,
    id: PresetIds.QRcode,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: qr,
    layout: { width: '110px', height: '77px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 92,
    height: 92,
  },
  {
    name: PresetNames.badgesVertical,
    id: PresetIds.badgesVertical,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: badgesVertical,
    layout: { width: '110px', height: '78px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 144,
    height: 94,
  },
  {
    name: PresetNames.titleAndQRCode,
    id: PresetIds.titleAndQRCode,
    connectedMobilePresetID: MobilePresetIds.titleAndQRCode,
    src: titleQr,
    layout: { width: '109px', height: '148px' },
    withoutElements: [
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 136,
    height: 194,
  },
  {
    name: PresetNames.imgOnly,
    id: PresetIds.imgOnly,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: imageOnly2,
    layout: { width: '110px', height: '148px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.badges.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
    ],
    isDisplayOnEditorX: false,
    width: 316,
    height: 493,
  },
  {
    name: PresetNames.centerTitleSubBadge,
    id: PresetIds.centerTitleSubBadge,
    connectedMobilePresetID: MobilePresetIds.centerTitleSubBadge,
    src: centerTitleSubBadge,
    layout: { width: '240px', height: '54px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.smsInputs.role,
      appElements.QRCodeWithSubTitle.role,
    ],
    isDisplayOnEditorX: false,
    width: 450,
    height: 181,
  },
];

export const getVariationId = (preset: string) => {
  // remove externalPreset-
  return preset; // .slice(preset.indexOf('-') + 1);
};
