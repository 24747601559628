import { WidgetDesignManifestBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignManifestBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { defaultHelpId } from '../../constants';
import globalDesignBuilder from '../../utils/globalDesignBuilder';
import mainPresetsBuilder from '../../utils/mainPresetsBuilder';
import { WidgetBuilder } from '@wix/yoshi-flow-editor';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  t: any,
  editorSDK: any,
) => {
  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('download_my_app.widget.global_design.edit'),
      actionType: 'design',
      actionId: '',
      // behavior: 'HIDE',
    })
    .set('animation', { behavior: 'DEFAULT' })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: defaultHelpId });
};

const setMobileGFPP = (controllerBuilder: WidgetBuilder, t) => {
  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;

  widgetBuilder.set({
    displayName: t('download_my_app.widget.presets_widget_label'),
    nickname: 'presstsWidget',
  });
  setDesktopGFPP(widgetBuilder, t, editorSDK);
  setMobileGFPP(widgetBuilder, t);

  widgetBuilder.configureWidgetDesign(
    (widgetDesignBuilder: WriteOnlyBuilder<WidgetDesignManifestBuilder>) => {
      widgetDesignBuilder.set({
        title: t('download_my_app.widget.global_design.title'),
        presetsTitle: t('download_my_app.widget.global_design.presets'),
      });
      mainPresetsBuilder(widgetDesignBuilder, isEditorX);
      globalDesignBuilder(widgetDesignBuilder, t);
    },
  );

  widgetBuilder.configureConnectedComponents(
    'title',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.title.label'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('download_my_app.widget.title.main_action_2'),
          onClick: (event) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: event.detail.componentRef,
              panelSectionsDefinition: {
                placeholder: 'hidden',
                required: 'hidden',
              },
            });
          },
        })
        .set('mainAction2', {
          label: t('download_my_app.widget.title.main_action'),
          actionId: 'editTitle',
        })
        .set('link', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'description',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.description.label'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('download_my_app.widget.description.main_action_2'),
          onClick: (event) => {
            editorSDK.editor.openNativeComponentPanel('', 'settings', {
              componentRef: event.detail.componentRef,
              panelSectionsDefinition: {
                placeholder: 'hidden',
                required: 'hidden',
              },
            });
          },
        })
        .set('mainAction2', {
          label: t('download_my_app.widget.description.main_action'),
          actionId: 'editDescription',
        })
        .set('link', { behavior: 'HIDE' })

        .set('connect', { behavior: 'HIDE' });
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'devicebg',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.deviceBg.label'),
      });

      connectedComponentsBuilder.gfpp().set('mainAction1', {
        label: t('download_my_app.widget.deviceBg.main_action'),
        actionType: 'design',
        actionId: '',
      });
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'qrAndTitleWrapper',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.qrBackground.label'),
      });

      connectedComponentsBuilder.gfpp().set('design', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'box7',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.background.label'),
      });

      connectedComponentsBuilder.gfpp().set('mainAction1', {
        label: t('download_my_app.widget.deviceBg.main_action'),
        actionType: 'design',
        actionId: '',
      });
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', { behavior: 'HIDE' });
    },
  );
};
