import type {
  EditorReadyOptions,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { editorAppEventListener } from './editorAppEventListener';
import { MobilePresetIds, PresetIds } from '../enums/presets';
import { getVariationId } from '../utils/presets';
import { installWidget } from './installWidget';

const presets = {
  desktop: getVariationId(PresetIds.defaultBoxNew),
  mobile: MobilePresetIds.defaultBox,
};

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  if (options.firstInstall) {
    await installWidget(editorSDK);
  }

  editorAppEventListener(editorSDK, flowAPI, options);
};
