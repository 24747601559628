import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getVariationId, presets as mainPresets } from '../../utils/presets';
import { PresetIds } from '../../enums/presets';
import { removeElement } from './removeElement';
import appElements from '../../utils/appElements';

export const onPresetChange = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event,
) => {
  const { preset, componentRef } = event.detail;
  const selectedPreset =
    mainPresets.find((p) => preset === p.id) || mainPresets[0];

  const [parentRefComponent] = await editorSDK.document.components.getAncestors(
    '',
    {
      componentRef,
    },
  )!;
  debugger;
  // if (preset.startsWith('externalPreset-')) {
  const variationId = getVariationId(preset);
  // reset design changes before changing template
  if (variationId && parentRefComponent) {
    await editorSDK.components.refComponents.removeAllOverrides('', {
      componentRef: parentRefComponent,
    });
    await editorSDK.application.appStudioWidgets.changePreset('', {
      context: { viewport: 'DESKTOP' },
      componentRef: parentRefComponent,
      stylePresetId: variationId,
      layoutPresetId: variationId,
    });
    const connectedMobilePresetId = selectedPreset.connectedMobilePresetID;
    if (connectedMobilePresetId) {
      await editorSDK.application.appStudioWidgets.changePreset('', {
        context: { viewport: 'MOBILE' },
        componentRef: parentRefComponent,
        stylePresetId: connectedMobilePresetId,
        layoutPresetId: connectedMobilePresetId,
      });
    }
    // await editorSDK.document.application.livePreview.refresh('', {
    //   shouldFetchData: true,
    //   source: '',
    // });
  }
  // }
  if (flowAPI.environment.isEditorX) {
    //         const responsiveLayout = await editorSDK.document.responsiveLayout.get(
    //           'token',
    //           { componentRef: rootWidgetParentRef }
    //         );
    //         debugger;
    //         console.log('responsiveLayout ', responsiveLayout);
    //         responsiveLayout.componentLayouts[0].width = {
    //           type: 'px',
    //           value: selectedPreset.width,
    //         };
    //         debugger;
    //         await editorSDK.document.responsiveLayout.update('token', {
    //           componentRef: rootWidgetParentRef,
    //           responsiveLayout,
    //         });
  } else {
    editorSDK.document.components.layout.update('', {
      componentRef: parentRefComponent,
      layout: {
        width: selectedPreset.width,
        height: selectedPreset.height,
      },
    });
  }
  if (preset === PresetIds.centerTitleSubBadge) {
    await removeElement(editorSDK, event, appElements.QRCode.role);
  }
};
